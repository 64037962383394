<script>
	import {onMount, createEventDispatcher} from 'svelte';

	export let notaryData;

	const dispatch = createEventDispatcher();

	// The url here can be a remote url or a relative path to a local file.
	// --
	const documentTypes = [
		{
			displayValue: 'General All Purpose Acknowledgement',
			state: '*',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1601911388/SiteAssets/GAPA.pdf',
			value: 'GAPA',
		},
 
		{
			displayValue: 'Copy Certification by Document Custodian',
			state: '*',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1723229322/SiteAssets/Copy_Certification_by_Document_Custodian.pdf',
			value: 'CCDC',
		},

		{
			displayValue: 'CA Authorization Form',
			state: '*',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1721411144/SiteAssets/notarial_certificates/usa_states/CA/CA-AF.pdf',
			value: 'CAAuthorizationForm',
		},

		{
			displayValue: 'Certificate of Acknowledgment (Representative Capacity)',
			state: 'CO',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1716229256/SiteAssets/notarial_certificates/usa_states/CO/CO-Acknowledgement-Individual-Acting-in-a-Representative-Capacity.pdf',
			value: 'COCertofAckRepresentative',
		},

		{
			displayValue: 'Certificate of Acknowledgment (Individual Capacity)',
			state: 'CO',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1716229256/SiteAssets/notarial_certificates/usa_states/CO/CO-Acknowledgement-Individual-Acting-in-His-Her-Own-Right.pdf',
			value: 'COCertofAckIndividual',
		},

		{
			displayValue: 'Certificate of a Document Photocopy',
			state: 'CO',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1716229256/SiteAssets/notarial_certificates/usa_states/CO/CO-Certification-of-a-Document-Photocopy.pdf',
			value: 'COCertofDocPhotoCopy',
		},

		{
			displayValue: 'For Verification Upon Oath or Affirmation',
			state: 'CO',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1716229256/SiteAssets/notarial_certificates/usa_states/CO/CO-Verification-on-Oath-or-Affirmation.pdf',
			value: 'COForVerificationUponOathOrAffirmation',
		},

		{
			displayValue: 'Witnessing or Attesting a Signature',
			state: 'CO',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1716229256/SiteAssets/notarial_certificates/usa_states/CO/CO-Witnessing-or-Attesting-a-Signature.pdf',
			value: 'COWitnessOrAttestSignature',
		},

		{
			displayValue: 'Acknowledgment in a Representative Capacity',
			state: 'DE',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1727800552/SiteAssets/notarial_certificates/usa_states/DE/DE-Acknowledgment-in-a-Representative-Capacity.pdf',
			value: 'DEAcknowledgmentInARepresentativeCapacity',
		},

		{
			displayValue: 'Acknowledgment in an Individual Capacity',
			state: 'DE',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1727800552/SiteAssets/notarial_certificates/usa_states/DE/DE-Acknowledgment-in-an-Individual-Capacity.pdf',
			value: 'DEAcknowledgmentInAnIndividualCapacity',
		},

		{
			displayValue: 'Certified Copy of a Document',
			state: 'DE',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1727800552/SiteAssets/notarial_certificates/usa_states/DE/DE-Certified-Copy-of-a-Document.pdf',
			value: 'DECertifiedCopyOfADocument',
		},

		{
			displayValue: 'Verification Upon Oath or Affirmation',
			state: 'DE',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1727800552/SiteAssets/notarial_certificates/usa_states/DE/DE-Verification-Upon-Oath-or-Affirmation.pdf',
			value: 'DEVerificationUponOathOrAffirmation',
		},

		{
			displayValue: 'Witnessing or Attesting a Signature',
			state: 'DE',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1727800553/SiteAssets/notarial_certificates/usa_states/DE/DE-Witnessing-or-Attesting-a-Signature.pdf',
			value: 'DEWitnessingOrAttestingASignature',
		},

		{
			displayValue: 'Jurat',
			state: 'FL',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620151127/SiteAssets/notarial_certificates/usa_states/FL/FL-Jurat-Updated.pdf',
			value: 'FLJurat',
		},

		{
			displayValue: 'Certificate of Acknowledgment (Representative Capacity)',
			state: 'FL',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1603899965/SiteAssets/notarial_certificates/usa_states/FL/FL-Certificate-of-Acknowledgment-Representative.pdf',
			value: 'FLCertofAckRepresentative',
		},

		{
			displayValue: 'Certificate of Acknowledgment (Individual Capacity)',
			state: 'FL',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1603899992/SiteAssets/notarial_certificates/usa_states/FL/FL-Certificate-of-Acknowledgment-Individual.pdf',
			value: 'FLCertofAckIndividual',
		},

		{
			displayValue: 'Credible Witness Affidavit',
			state: 'FL',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1668522280/SiteAssets/notarial_certificates/usa_states/FL/Florida-Credible-Witness-Affidavit.pdf',
			value: 'FLCredibleWitnessAffidavit',
		},

		{
			displayValue: 'Oath or Affirmation',
			state: 'FL',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1603899992/SiteAssets/notarial_certificates/usa_states/FL/FL-Oath-or-Affirmation.pdf',
			value: 'FLOathAffirmation',
		},

		{
			displayValue: 'Certified Copy of Notarial Record',
			state: 'FL',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620151127/SiteAssets/notarial_certificates/usa_states/FL/FL-Certified-Copy-of-Notarial-Record-Updated.pdf',
			value: 'FLCertifiedCopyNotarialRecord',
		},

		{
			displayValue: 'Jurat',
			state: 'MO',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620306395/SiteAssets/notarial_certificates/usa_states/MO/MO-Jurat.pdf',
			value: 'MOJurat',
		},

		{
			displayValue: 'Acknowledgment',
			state: 'MO',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620306395/SiteAssets/notarial_certificates/usa_states/MO/MO-Acknowledgement.pdf',
			value: 'MOAcknowledgment',
		},

		{
			displayValue: 'Acknowledgement',
			state: 'NH',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1682102855/SiteAssets/notarial_certificates/usa_states/NH/Acknowledgement.pdf',
			value: 'NHAcknowledgement',
		},

		{
			displayValue: 'Certification for an Acknowledgement Made in an Individual Capacity',
			state: 'NH',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1682102855/SiteAssets/notarial_certificates/usa_states/NH/Certification_for_an_Acknowledgement_Made_in_an_Individual_Capacity.pdf',
			value: 'NHCertificationForAnAcknowledgementMadeInAnIndividualCapacity',
		},

		{
			displayValue: 'Certification for Witnessing or Attesting a Signature',
			state: 'NH',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1682102855/SiteAssets/notarial_certificates/usa_states/NH/Certification_for_Witnessing_or_Attesting_a_Signature.pdf',
			value: 'NHCertificationForWitnessingOrAttestingASignature',
		},

		{
			displayValue: 'Certification of Verification Upon Oath or Affirmation (Jurat)',
			state: 'NH',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1682102855/SiteAssets/notarial_certificates/usa_states/NH/Certification_of_Verification_Upon_Oath_or_Affirmation_Jurat.pdf',
			value: 'NHCertificationOfVerificationUponOathOrAffirmationJurat',
		},

		{
			displayValue: 'Copy Certification for a Document',
			state: 'NH',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1682102855/SiteAssets/notarial_certificates/usa_states/NH/Copy_Certification_for_a_Document.pdf',
			value: 'NHCopyCertificationForADocument',
		},

		{
			displayValue: 'Copy Certification Jurat',
			state: 'NH',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1682102856/SiteAssets/notarial_certificates/usa_states/NH/Copy_Certification_Jurat.pdf',
			value: 'NHCopyCertificationJurat',
		},

		{
			displayValue: 'Jurat Certificate',
			state: 'NJ',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1705607998/SiteAssets/notarial_certificates/usa_states/NJ/New_Jersey_Jurat.pdf',
			value: 'NJCopyCertificationJurat',
		},

		{
			displayValue: 'Notary Acknowledgment',
			state: 'NJ',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1705607998/SiteAssets/notarial_certificates/usa_states/NJ/New_Jersey_Notary_Acknowledgment.pdf',
			value: 'NJAllPurposeAcknowledgment',
		},

		{
			displayValue: 'All Purpose Acknowledgment',
			state: 'NY',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1686853490/SiteAssets/notarial_certificates/usa_states/NY/NY-All-Purpose-Acknowledgement.pdf',
			value: 'NYAllPurposeAcknowledgment',
		},

		{
			displayValue: 'Jurat',
			state: 'NY',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1686853490/SiteAssets/notarial_certificates/usa_states/NY/NY-Juart.pdf',
			value: 'NYJurat',
		},

		{
			displayValue: 'Acknowledgment (Individual)',
			state: 'OK',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1603899992/SiteAssets/notarial_certificates/usa_states/OK/OK-Acknowledgment-Individual.pdf',
			value: 'OKAcknowledgmentIndividual',
		},

		{
			displayValue: 'Acknowledgment (Representative)',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1603899992/SiteAssets/notarial_certificates/usa_states/OK/OK-Acknowledgment-Representative.pdf',
			value: 'OKAcknowledgmentRepresentative',
		},

		{
			displayValue: 'For Verification Upon Oath or Affirmation',
			state: 'OK',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1603899992/SiteAssets/notarial_certificates/usa_states/OK/OK-For-Verification-Upon-Oath-or-Affirmation.pdf',
			value: 'OKForVerificationUponOathOrAffirmation',
		},

		{
			displayValue: 'Proof of Execution by Subscribing Witness',
			state: 'OK',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620150555/SiteAssets/notarial_certificates/usa_states/OK/OK-Proof-of-Execution-by-Subscribing-Witness-Updated.pdf',
			value: 'OKProofofExecutionbySubscribingWitness',
		},

		{
			displayValue: 'Certified Copy of Notarial Record',
			state: 'OK',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620150555/SiteAssets/notarial_certificates/usa_states/OK/OK-Certified-Copy-of-Notarial-Record-Updated.pdf',
			value: 'OKCertifiedCopyNotarialRecord',
		},

		{
			displayValue: 'Acknowledgment (Individual Capacity)',
			state: 'PA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1734026744/SiteAssets/notarial_certificates/usa_states/PA/PA-Acknowledgment-in-an-Individual-Capacity.pdf',
			value: 'PAAcknowledgmentIndividual',
		},

		{
			displayValue: 'Acknowledgment (Representative Capacity)',
			state: 'PA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1734026744/SiteAssets/notarial_certificates/usa_states/PA/PA-Acknowledgment-in-a-Representative-Capacity.pdf',
			value: 'PAAcknowledgmentRepresentative',
		},

		{
			displayValue: 'For Verification Upon Oath or Affirmation',
			state: 'PA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1734026745/SiteAssets/notarial_certificates/usa_states/PA/PA-Verification-Upon-Oath-or-Affirmation.pdf',
			value: 'PAForVerificationUponOathOrAffirmation',
		},

		{
			displayValue: 'Witnessing or Attesting a Signature',
			state: 'PA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1734026745/SiteAssets/notarial_certificates/usa_states/PA/PA-Witnessing-or-Attesting-a-Signature.pdf',
			value: 'PAWitnessingOrAttestingASignature',
		},

		{
			displayValue: 'Certified Copy of Notarial Record',
			state: 'PA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620150555/SiteAssets/notarial_certificates/usa_states/OK/OK-Certified-Copy-of-Notarial-Record-Updated.pdf',
			value: 'PACertifiedCopyNotarialRecord',
		},

		{
			displayValue: 'Acknowledgment',
			state: 'RI',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1736365131/SiteAssets/notarial_certificates/usa_states/RI/Acknowledgement.pdf',
			value: 'RIAcknowledgement',
		},

		{
			displayValue: 'Copy Certification',
			state: 'RI',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1736365131/SiteAssets/notarial_certificates/usa_states/RI/Copy_Certification.pdf',
			value: 'RICopyCertification',
		},

		{
			displayValue: 'Jurat',
			state: 'RI',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1736365132/SiteAssets/notarial_certificates/usa_states/RI/Jurat.pdf',
			value: 'RIJurat',
		},

		{
			displayValue: 'Witness a Signature',
			state: 'RI',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1736365132/SiteAssets/notarial_certificates/usa_states/RI/Witness_a_Signature.pdf',
			value: 'RIWitnessASignature',
		},

		{
			displayValue: 'Jurat',
			state: 'TX',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620148835/SiteAssets/notarial_certificates/usa_states/TX/TX-Jurat-Updated.pdf',
			value: 'TXJurat',
		},

		{
			displayValue: 'Ordinary Certificate of Acknowledgement',
			state: 'TX',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620148834/SiteAssets/notarial_certificates/usa_states/TX/TX-Ordinary-Certificate-of-Acknowledgement-Updated.pdf',
			value: 'TXOrdinaryCertificateofAcknowledgement',
		},

		{
			displayValue: 'Oath or Affirmation',
			state: 'TX',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620148835/SiteAssets/notarial_certificates/usa_states/TX/TX-Oath-or-Affirmation-Updated.pdf',
			value: 'TXOathOrAffirmation',
		},

		{
			displayValue: 'Proof of Execution by Subscribing Witness',
			state: 'TX',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1620148834/SiteAssets/notarial_certificates/usa_states/TX/TX-Proof-of-Execution-by-Subscribing-Witness-Updated.pdf',
			value: 'TXProofofExecutionbySubscribingWitness',
		},

		{
			displayValue: 'Certified Copy of Notarial Record',
			state: 'TX',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1615215925/SiteAssets/notarial_certificates/usa_states/TX/TX-Certified-Copy-Notarial-Record.pdf',
			value: 'TXCertifiedCopyNotarialRecord',
		},

		{
			displayValue: 'Jurat',
			state: 'VA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1715884425/SiteAssets/notarial_certificates/usa_states/VA/VA-Jurat.pdf',
			value: 'VAJurat', 
		},

		{
			displayValue: 'Certificate of Acknowledgement',
			state: 'VA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1715884425/SiteAssets/notarial_certificates/usa_states/VA/VA-Certificate-of-Acknowledgement.pdf',
			value: 'VACertificateOfAcknowledgement',
		},

		{
			displayValue: 'Electronic Notarization',
			state: 'VA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1715884424/SiteAssets/notarial_certificates/usa_states/VA/VA-Electronic-Notarization.pdf',
			value: 'VAElectronicNotarization',
		},

		{
			displayValue: 'Certified Copy of an Original',
			state: 'VA',
			url: 'https://res.cloudinary.com/dzex7hjqn/image/upload/v1715884425/SiteAssets/notarial_certificates/usa_states/VA/VA-Certified-Copy-of-an-Original.pdf',
			value: 'VACertifiedCopyOfAnOriginal',
		}, 
	];

	let chosenDocTypeValue = 'GAPA';
	let canUploadDocument = false;

	// -------------
	// - Functions -
	// -------------

	function addDocument() {
		for (let i = 0; i < documentTypes.length; i++) {
			const documentType = documentTypes[i];

			if (documentType.value === chosenDocTypeValue) {
				dispatch('addDocument', documentType);
				return closeModal();
			}
		}
	}

	function uploadDocument() {
		let files = document.getElementById('upload_document').files;

		if (files.length < 1 && files[0].type !== 'application/pdf') {
			return canUploadDocument = false;
		}

		// let isWordDocument = files[0].type !== 'application/msword'
		// 		|| files[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		//
		// if (isWordDocument) {
		// 	// TODO: convert file to pdf
		// }

		dispatch('uploadDocument', {
			file: files[0],
			type: chosenDocTypeValue,
		});

		return closeModal();
	}

	function closeModal() {
		dispatch('closeModal', {});
	}
</script>

<div>
	<div
			aria-labelledby="modal-title"
			aria-modal="true"
			class="relative z-10"
			role="dialog"
	>
		<!--
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
		<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

		<div class="fixed z-10 inset-0 overflow-y-auto">
			<div class="flex items-start sm:items-center justify-center min-h-full p-4 pt-24 text-center sm:p-0">
				<!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
				<div
						class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
					<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
						<div class="sm:flex sm:items-start">
							<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">What document would you
									like to
									add to this file?</h3>

								<div class="mt-2">
									<select
											bind:value={chosenDocTypeValue}
											class="border-b border-gray-300 flex-shrink-0 py-2 w-full"
									>
										<option value="upload">Upload a Document</option>
										{#each documentTypes as documentType}
											{#if documentType.state === '*' || notaryData.commission_type === documentType.state}
												<option value={documentType.value}>{documentType.displayValue}</option>
											{/if}
										{/each}
									</select>
								</div>

								{#if (chosenDocTypeValue === 'upload')}
									<div class="mt-2">
										<input type="file"
													 id="upload_document"
												   accept="application/pdf"
											     class="border-b border-gray-300 flex-shrink-0 py-2 w-full"
													 on:change={() => canUploadDocument = true}
										/>
									</div>
								{/if}
							</div>
						</div>
					</div>

					<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
						{#if (chosenDocTypeValue === 'upload')}
							<button
									on:pointerup={() => uploadDocument()}
									class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 sm:ml-3 sm:w-auto sm:text-sm"
									type="button"
									class:opacity-35={!canUploadDocument}
									class:cursor-not-allowed={!canUploadDocument}
									disabled={!canUploadDocument || null}
							>Upload Document</button>
						{:else}
							<button
									on:pointerup={() => addDocument()}
									class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 sm:ml-3 sm:w-auto sm:text-sm"
									type="button"
							>Add Document</button>
						{/if}

						<button
								on:pointerup={() => closeModal()}
								class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
								type="button"
						>Cancel</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
