// When adding  a new color make sure to update the SIGNER_TEXT_COLORS with a color that contrasts well with the background color
const SIGNER_COLORS = {
    1: '#CEE4F9', // Blue
    2: '#D4FAE1', // Green
    3: '#F7E0CB', // Orange
    4: '#ECFACE', // Yellow-green
    5: '#F1CAF8', // Purple
    6: '#F4CBCA', // Pink
    7: '#FBFBCF', // Yellow
    8: '#CFF9C6', // Light green
    9: '#CDF7F4', // Cyan
    10: '#D8D2F9', // Lavender
  };
  
  const SIGNER_TEXT_COLORS = {
    1: '#4A90E2', // Stronger blue
    2: '#27AE60', // Stronger green
    3: '#E67E22', // Stronger orange
    4: '#A4B12A', // Stronger yellow-green
    5: '#9B59B6', // Stronger purple
    6: '#E74C3C', // Stronger pink/red
    7: '#B7A639', // Stronger yellow
    8: '#2ECC71', // Stronger light green
    9: '#1ABC9C', // Stronger cyan
    10: '#5B48D9', // Stronger lavender
  };
  
  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  }
  
  function getRgbString(hex) {
    const rgb = hexToRgb(hex);
    return rgb ? `${rgb.r}, ${rgb.g}, ${rgb.b}` : '0, 0, 0';
  }

  function getParticipantColors(participant, participants) {
    if (!participant || !participants) {
      return {
        backgroundColor: `rgba(206, 228, 249, 0.8)`, // Default color for SIGNER_COLORS[1]
        borderColor: '#4A90E2', // Default color for SIGNER_TEXT_COLORS[1]
        textColor: '#4A90E2', // Default stronger text color
        rgb: '206, 228, 249', // Default RGB
        hex: '#4A90E2', // Default HEX for text
      };
    }
  
    // Find participant's index
    const signerIndex = participants.findIndex(p => p.email === participant.email) + 1;
    const normalizedIndex = ((signerIndex - 1) % 10) + 1;
  
    const backgroundColor = SIGNER_COLORS[normalizedIndex];
    const textColor = SIGNER_TEXT_COLORS[normalizedIndex];
    const rgb = getRgbString(backgroundColor);
  
    return {
      backgroundColor: `rgba(${rgb}, 0.7)`, // Light background color
      borderColor: textColor, // Stronger color for borders
      textColor: textColor, // Stronger text color
      rgb: rgb, // RGB string
      hex: textColor, // HEX for text
    };
  }
  
  export { getParticipantColors };